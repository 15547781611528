/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  padding-bottom: 0;
  background-color: #f2f2f2;
  padding-top: 0;
  position: relative;
  margin-top: 12rem;

  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    margin-top: 7rem; }

  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    margin-top: 4rem; }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: url(../../assets/img/layout/contact-cover-line.svg);
    height: 128px;
    z-index: 910;
    background-position: center top;
    top: -110px;
    background-repeat: no-repeat !important;

    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      top: -81px; }

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      display: none; } }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: url(../../assets/img/layout/contact-cover.svg);
    height: 128px;
    z-index: 900;
    background-position: center top;
    top: -69px;
    background-repeat: no-repeat !important;

    @media only screen and (max-width: 1491px) {
      top: -59px; }

    @media only screen and (max-width: 1273px) {
      top: -47px; }

    @media only screen and (max-width: 1012px) {
      top: -40px; }

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      background: url(../../assets/img/layout/contact-cover-mobile.svg);
      top: -58px; }

    @media only screen and (max-width: 842px) {
      top: -50px; }

    @media only screen and (max-width: 725px) {
      top: -42px; }

    @media only screen and (max-width: 608px) {
      top: -35px; }

    @media only screen and (max-width: 505px) {
      top: -29px; }

    @media only screen and (max-width: 417px) {
      top: -25px; }

    @media only screen and (max-width: 359px) {
      top: -22px; } }

  .wrapper {
    max-width: $max;
    margin: 0;
    justify-content: space-between;
    display: flex;

    @media (max-width: #{map-get($breakpoints, l)}) {
      flex-direction: column; }

    .form-wrapper {
      flex: 0 0 50%;
      padding: 12rem 9rem 9rem 21rem;

      @media (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 9rem 9rem 9rem 15 * $base-width; }

      @media (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 9rem 5rem 9rem 9 * $base-width; }

      @media (max-width: #{map-get($breakpoints, l)}) {
        padding: 7rem 9 * $base-width 2rem 9 * $base-width; }

      @media (max-width: #{map-get($breakpoints, m)}) {
        padding: 7rem 5 * $base-width 2rem 5 * $base-width; }

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 7rem 3 * $base-width 2rem 3 * $base-width; } }

    .col-wrapper {
      flex: 0 0 50%;
      padding: 12rem 21rem 9rem 9rem;
      position: relative;

      // &::after
      //   background: url(../../assets/img/layout/vita-logo-bg.svg)
      //   content: ""
      //   position: absolute
      //   height: 500px
      //   width: 500px
      //   z-index: 99999
      //   background-position: center top
      //   background-repeat: no-repeat !important
      //   right: -250px
      //   bottom: -170px
      //   opacity: .1

      @media (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 9rem 15 * $base-width 9rem 9rem; }

      @media (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 9rem 9 * $base-width 9rem 7rem; }

      @media (max-width: #{map-get($breakpoints, l)}) {
        padding: 6rem 9 * $base-width 9rem 9 * $base-width; }

      @media (max-width: #{map-get($breakpoints, m)}) {
        padding: 5rem 5 * $base-width 7rem 5 * $base-width; }

      @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 5rem 3 * $base-width 6rem 3 * $base-width; }

      .text {
        padding: 4rem 0; }

      ul {
        padding-left: 2.4rem; } }

    form {
      .form-inner {
        .form-field-group {
          margin: 4 * $base-width 0;

          label {
            display: inline-block;
            margin-bottom: $base-width; }

          input[type="text"] {
            width: 100%;
            height: 7 * $base-width;
            padding: 2 * $base-width;
            border-radius: 8px;
            border: 1px solid $dark;
            font-size: 1.7rem;
            font-weight: 400;
            background-color: transparent;

            &:focus-visible {
              outline: none; } }

          textarea {
            width: 100%;
            min-height: 15 * $base-width;
            padding: 2 * $base-width;
            border-radius: 8px;
            border: 1px solid $dark;
            font-family: $font-default;
            font-size: 1.7rem;
            font-weight: 400;
            background-color: transparent;
            resize: none;

            &:focus-visible {
              outline: none; } }

          .checkbox {
            display: flex;
            cursor: pointer;

            #dsgvo {
              width: 25px;
              min-width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: $base-width;
              border-radius: 0;
              border: 1px solid $primary;
              cursor: pointer;

              &.error {
                &::after {
                  content: '';
                  width: 25px;
                  height: 25px;
                  position: absolute;
                  background-color: rgba($error,.25); }

                &:checked {
                  &::after {
                    background-color: rgba($error,.0); } } } } } }

        #confirm {
          position: absolute;
          left: -20000px; } }

      .form-success,
      .form-error {
        width: 100%;
        border: 2px solid $error;
        background-color: rgba($error,.2);
        padding: 3rem;
        margin-bottom: 3rem; }

      .form-success {
        border: 2px solid $success;
        background-color: rgba($success,.25); }

      .error {
        border-color: $error;
        background-color: rgba($error,.25); } } }

  button {
    border: none;
    font-family: $font-default;
    font-size: 1.8rem;
    cursor: pointer; } }
