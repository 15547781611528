.block-text-media {
    .wrapper {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: $boxed;
        margin: 0 auto;

        .btn {
            margin-top: 3*$base-width; }

        &.img-right {
            flex-direction: row-reverse;

            .media {
                &::before {
                    content: '';
                    background-color: $grey;
                    position: absolute;
                    height: 70%;
                    width: 70%;
                    right: -20px;
                    bottom: -20px;
                    border-radius: $border-radius;

                    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                        right: -10px;
                        bottom: -10px;
                        height: 60%;
                        width: 60%; } } } }

        &.img-left {
            flex-direction: row;

            .media {
                &::before {
                    content: '';
                    background-color: $grey;
                    position: absolute;
                    height: 70%;
                    width: 70%;
                    left: -20px;
                    bottom: -20px;
                    border-radius: $border-radius;

                    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                        left: -10px;
                        bottom: -10px;
                        height: 60%;
                        width: 60%; } } } }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row-reverse; } }

    .text {
        align-content: center;
        align-items: center;
        flex: 0 0 48%;
        width: 48%;
        padding: 8 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            min-height: unset;
            margin-top: 8 * $base-width;
            padding: 0;
            flex: 0 0 100%;
            width: 100%; }

        &.img-right {
            padding-left: 0; }

        &.img-left {
            padding-right: 0; } }

    .media {
        z-index: 2;
        min-height: 100%;
        flex: 0 0 52%;
        width: 52%;
        padding-top: calc(65.25% / 2);
        position: relative;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 56.25%;
            flex: 0 0 100%;
            width: 100%; }

        img, iframe, video, video[poster] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: $border-radius; }

        .video-poster, .video-poster img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            z-index: 1;
            cursor: pointer; }

        .play-button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 80px;
            height: 80px;
            margin: auto;
            background-color: $light;
            border-radius: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $primary;
                display: inline-block; } } } }
