.block-text-columns {

    .text-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4 * $base-width;

        h3, h4 {
            color: $primary-darker; }

        .col {
            padding: 4 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-bottom: 0; }

            iframe {
                height: 680px;
                width: 100%;
                border-radius: $border-radius;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    height: 550px; }

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    height: 450px; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    height: 350px; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    height: 250px; } }

            &.col-1 {
                flex: 0 0 100%;
                width: 100%; }

            &.col-2 {
                flex: 0 0 50%;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            &.col-3 {
                flex: 0 0 33.333%;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            &.col-4 {
                flex: 0 0 25%;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 33.333%; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            ul {
                padding-left: 3 * $base-width;

                li {
                    margin-bottom: $base-width; } }


            .btn {
                margin-top: 2 * $base-width; } } } }
