.block-employee {
  .wrapper {
    .text-content {
      padding-bottom: 6 * $base-width; }

    #popup-modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($dark,0.7);
      z-index: 9999;
      display: none;
      align-items: center;
      justify-content: center;
      height: 100vh;

      @media (max-width: #{map-get($breakpoints, s)}) {
        height: calc(100vh - 40px);
        padding-top: 8rem;
        overflow-y: scroll; }

      &.show {
        display: flex; }

      .popup {
        max-width: 1010px;
        width: 100%;
        margin: auto;
        background-color: $light;
        box-shadow: 0px 7px 55px rgba(0, 0, 0, .15);

        @media (max-width: #{map-get($breakpoints, l)}) {
          margin: 0 2rem; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          margin: auto; }

        &#sonja {
          max-width: 1220px;
          width: 100%;

          @media (max-width: #{map-get($breakpoints, s)}) {
            .ul-wrapper {
              display: block; } } }

        .button {
          padding: 0 6.25 * $base-width;
          position: relative;
          cursor: pointer;

          @media (max-width: #{map-get($breakpoints, m)}) {
            margin-bottom: 0; }

          &:after, &:before {
            position: absolute;
            right: 5 * $base-width;
            content: ' ';
            height: 25px;
            width: 2px;
            top: 2.5 * $base-width;
            background-color: $primary;

            @media (max-width: #{map-get($breakpoints, s)}) {
              top: 3rem;
              right: 4rem; } }

          &:after {
            transform: rotate(45deg); }

          &:before {
            transform: rotate(-45deg); } } }

      .popup-inner {
        padding: 8.75 * $base-width;
        display: flex;
        align-items: center;
        gap: 25px;
        box-shadow: 0px 7px 55px rgba(0, 0, 0, 0.15);

        @media (max-width: #{map-get($breakpoints, l)}) {
          gap: 0; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          padding: 4 * $base-width; } }

      ul {
        padding-left: 2.4rem; }

      .infotext {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: #{map-get($breakpoints, lg)}) {
          flex-wrap: wrap; }

        svg {
          max-width: 38px;
          width: 100%;
          @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
            margin-bottom: 20px; } } }

      .image {
        padding-top: 44%;
        flex: 0 0 38%;
        position: relative;

        @media (max-width: #{map-get($breakpoints, l)}) {
          display: none; }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; } }

      .text-wrapper {
        padding-left: 5 * $base-width;

        @media (max-width: #{map-get($breakpoints, l)}) {
          padding-left: 0; }

        .h2 {
          font-size: 4rem;

          @media (max-width: #{map-get($breakpoints, l)}) {
            font-size: 2.8rem; } }

        p {
          padding: 0;
          font-size: 1.8rem; } } }

    .team-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0 - 3 * $base-width;

      .ul-wrapper {
        display: flex; }

      .team-item {
        flex: 0 0 33.33%;
        max-width: 33.333%;
        padding: 3 * $base-width;
        display: flex;
        cursor: pointer;

        @media (max-width: #{map-get($breakpoints, l)}) {
          flex: 0 0 50%;
          max-width: 50%; }

        @media (max-width: #{map-get($breakpoints, s)}) {
          flex: 0 0 100%;
          max-width: 100%; }

        .svg-wrapper {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          right: 0;
          top: -115px;
          bottom: 0;
          opacity: 0;

          @media (max-width: #{map-get($breakpoints, s)}) {
            display: none; } }

        svg {
          position: absolute;
          height: 100px;
          width: 100px;
          z-index: 1;
          fill: $light; }

        .inner {
          flex: 0 0 100%;
          position: relative;

          picture {
            padding-top: 100%;
            position: relative;
            display: block;

            &::after {
              content: "";
              height: 100%;
              width: 100%;
              display: block;
              top: auto;
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;
              background-color: $primary;
              opacity: 0;
              transition: all 0.3s ease-in-out;
              border-radius: 100%;

              @media (max-width: #{map-get($breakpoints, s)}) {
                display: none; } }

            img {
              position: absolute;
              border-radius: 100%;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              object-position: center;
              object-fit: cover; } }

          &:hover {
            .svg-wrapper {
              opacity: 1; }

            picture {
              &::after {
                opacity: .6; } } }

          .body {
            padding: 3 * $base-width;
            text-align: center;

            p {
              padding-bottom: 0;

              strong {
                @media (max-width: #{map-get($breakpoints, xl)}) {
                  hyphens: auto;
                  line-break: anywhere; } } }

            .ort {
              margin-bottom: $base-width; }

            a {
              display: inline-flex;
              align-items: center;
              line-height: 2.2rem;

              svg {
                width: 2rem;
                height: 2.5rem;
                margin-right: $base-width;
                fill: $primary; } } } } } } } }
