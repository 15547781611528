header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10000;
  background-color: $light;
  max-width: $max;
  margin: 0 auto;

  .inner {
    padding: 0 25 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
      padding: 0 15 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xxl)}) {
      padding: 0 9 * $base-width; }

    @media (max-width: #{map-get($breakpoints, m)}) {
      padding: 0 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
      padding: 0 3 * $base-width; } }

  &.fixed {
    box-shadow: 0 0 7px rgba(0, 0, 0, .15);
    .wrapper {
      .logo {
        width: 130px;

        img {
          width: 100px;
          transition: $transition; } } } }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2 * $base-width 0;

    .logo {
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        width: 130px; }

      img {
        width: 130px;
        transition: $transition;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          width: 100px; } } }

    nav {
      ul.menu {
        list-style: none;
        display: flex;
        align-items: center;

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          position: absolute;
          top: 100%;
          height: calc(100vh - 92px);
          background-color: $light;
          right: -120%;
          flex-direction: column;
          overflow-y: auto;
          padding: 6 * $base-width 3 * $base-width;
          transition: $transition;
          display: block;
          min-width: 45%;
          box-shadow: 0 10px 15px rgba($dark, 0.1);

          &.open {
            right: -10 * $base-width;
            transition: $transition; } }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          height: calc(100vh - 76px);
          min-width: 60%;

          &.open {
            right: -6 * $base-width;
            transition: $transition; } }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          min-width: 80%;
          &.open {
            right: -3 * $base-width;
            transition: $transition; } }

        li {
          position: relative;
          padding: 0 1 * $base-width;

          &.contact {
            a {
              background-color: $primary;
              border-radius: 38px;
              padding: 1.5*$base-width 4*$base-width;
              color: white;
              position: relative;
              transition: $transition;

              @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                margin-top: 3*$base-width;
                width: 100%;
                text-align: center; }

              &.active {
                color: $light; }

              &:hover {
                background-color: $primary-darker;

                a::before {
                  background-color: $primary-darker; } } } }

          a {
            padding: 2 * $base-width 2 * $base-width;

            &.active {
              color: $primary; }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
              padding: 2 * $base-width $base-width;
              font-size: 1.7rem; } }

          &.highlight {

            text-align: center;

            a {
              color: $light;
              padding: 2 * $base-width 4 * $base-width;
              background-color: $primary;
              border-radius: 30px;

              @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                width: 100%;
                margin-top: 2 * $base-width; }

              &:hover {
                background-color: $primary;
                animation: pulsePrimary 1500ms 1; } } }

          &.children {
            .level-1 {
              opacity: 0;
              visibility: hidden;
              list-style: none;
              position: absolute;
              background-color: $light;
              width: max-content;
              left: 0;
              min-width: 100%;
              top: calc(100% + 1.6rem);
              transition: $transition;
              border-top: 1px solid $secondary;
              box-shadow: 0 8px 15px rgba($dark, 0.1);

              @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                position: relative;
                opacity: 1;
                visibility: visible;
                top: 0;
                box-shadow: none;
                border: 0;

                li {
                  padding: $base-width 2 * $base-width; } } }

            .nav-parent-toggle {
              background: none;
              border: 0;
              padding-left: 4px;
              font-size: 1.6rem; } }

          &:hover {
            .nav-parent-toggle {
              color: $secondary;
              transition: $transition; }

            .level-1 {
              opacity: 1;
              visibility: visible;
              transition: $transition; } } } } } }

  // MOBILE TOGGLER
  .navbar-toggler {
    display: none;
    align-items: center;
    height: 44px;
    width: 44px;
    position: relative;
    border: 0;
    background: none;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      display: flex; }

    .line {
      height: 2px;
      width: 35px;
      background-color: $primary;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;

      &.top {
        top: 12px;
        transition: $transition; }

      &.middle {
        top: 50%;
        transform: translateY(-50%);
        transition: $transition; }

      &.bottom {
        bottom: 12px;
        transition: $transition; } }

    &.open {
      &:hover {
        .line {
          &.top, &.bottom {
            top: 50%;
            transition: $transition; } } }

      .line {
        &.middle {
          opacity: 0; }

        &.top {
          top: 50%;
          transform: rotate(45deg);
          transition: $transition; }

        &.bottom {
          top: 50%;
          transform: rotate(-45deg);
          transition: $transition; } } } } }
