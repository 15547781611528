.block-teaser-slider {
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-bottom: 4rem; }

    .text {
        max-width: $small;
        margin: 0 auto; }

    .teaser-slider {
        display: flex;
        flex-wrap: wrap;
        padding: 5*$base-width 0;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-bottom: 0; }

        @media (max-width: #{map-get($breakpoints, l)}) {
            padding: 0; }

        .owl-nav {
            position: absolute;
            display: flex;
            justify-content: space-between;

            @media (max-width: 1127px) {
                top: 40%;
                left: -5 * $base-width;
                right: -5 * $base-width; }

            @media (max-width: 900px) {
                left: -4.5*$base-width;
                right: -4.5$base-width;
                top: 24%; }

            @media (max-width: 630px) {
                left: -2*$base-width;
                right: -2*$base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                top: 22%; }

            button {
                height: 50px;
                width: 50px;
                background-color: $primary;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: $transition;
                border-radius: 100%;

                svg {
                    fill: $light;
                    width: 20px;
                    transition: $transition;

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        display: flex;
                        justify-content: center; } }

                &.disabled {
                    cursor: no-drop;

                    svg {
                        opacity: 0.4; } } } }

        .owl-item {

            display: flex;
            justify-content: center; }

        .teaser-item {
            flex: 0 0 23.3%;
            transition: $transition;
            background-color: $light;
            align-items: center;
            justify-content: flex-start;
            display: flex;
            width: 23.3%;
            margin: 1rem;
            gap: 20px;
            padding: 2.5*$base-width;
            border-radius: $border-radius;

            @media (max-width: 1520px) {
                flex: 0 0 31.3%;
                width: 31.3%; }

            @media (max-width: 1127px) {
                flex: 0 0 100%;
                width: 100%;
                height: 11rem; }

            @media (max-width: 630px) {
                flex: 0 0 80%;
                width: 80%; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                height: 9rem; }

            &:hover {
                svg {
                    transform: rotate(20deg); }

                @media (max-width: 1127px) {
                    svg {
                        transform: rotate(0); } } }

            p {
                font-weight: 600;

                @media (max-width: 1127px) {
                    font-size: 1.6rem; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    font-size: 1.5rem; } }

            .svg {
                border-radius: 100%;
                height: 80px;
                max-width: 80px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #c0dde1;

                @media (max-width: 1127px) {
                    height: 55px;
                    max-width: 55px;
                    width: 100%; }


                svg {
                    transition: $transition;
                    width: 40px;
                    height: 50px;

                    @media (max-width: 1127px) {
                        width: 30px;
                        height: 40px; } } } } } }



.block-text-images + .block-teaser-slider {
    padding-top: 0; }
