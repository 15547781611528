/* Third Party */
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/button';
@import './layout/404';

/* Blocks */
@import '../../blocks/hero-image/style';
@import '../../blocks/employee/style';
@import '../../blocks/teaser-slider/style';
@import '../../blocks/form/style';
@import '../../blocks/text-media/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/gallery/style';
@import '../../blocks/accordion/style';
