.block-accordion {
  padding-right: 4 * $base-width;
  padding-left: 4 * $base-width;

  .wrapper {
    max-width: $small;
    margin: 0 auto;

    table {
      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        width: 100%;

        thead {
          display: none; }

        tr {
          display: block;
          padding: 1rem 3rem 1rem 0;
          border-top: 2px solid #e2e1e1;

          &:first-child {
            border-top: 0; } } }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        margin-left: 1rem; }

      th {
        text-align: left;
        font-weight: 600; }

      td {
        padding: 1rem 5rem 1rem 0;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          display: flex;
          font-size: 1.6rem;

          &::before {
            content: attr(label);
            width: 100px;
            min-width: 100px;
            font-weight: 600; } } } }

    .text {
      margin-bottom: 4 * $base-width;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        margin-bottom: 2*$base-width; }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        margin-left: 1rem; } }

    .headline-wrapper + .accordion {
      margin-top: 5 * $base-width; }

    .accordion {
      .accordion-item {
        margin-bottom: 3 * $base-width;

        &:last-child {
          margin-bottom: 0; }

        .accordion-heading {
          border-radius: 19px;
          font-weight: 500;
          padding: .5rem;
          background-color: $primary-lighter;
          color: $dark;
          transition: $transition;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          &:hover, &.active {
            background-color: $primary;
            color: $light;
            transition: $transition;

            .svg {
              svg {
                transform: rotate(180deg);
                transition: $transition;
                fill: $light; } } }

          .heading-text {
            padding: 2 * $base-width 3 * $base-width; }

          .svg {
            background-color: transparent;
            display: flex;
            align-items: center;
            padding: 2 * $base-width 3 * $base-width;

            svg {
              fill: $dark;
              width: 15px;
              height: auto;
              transition: $transition; } } }

        .accordion-body {
          padding: 3.5 * $base-width;

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-left: 0; }

          .text {
            margin-bottom: 0; }

          ul {
            padding-left: 3 * $base-width; }

          a {
            text-decoration: underline; } } } } } }
