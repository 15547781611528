footer {
  background-color: $primary;
  padding: 5 * $base-width 16 * $base-width;

  @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
    padding: 5 * $base-width 10 * $base-width; }

  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    padding: 9 * $base-width 6 * $base-width; }

  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding: 7 * $base-width 3 * $base-width; }

  .wrapper {
    .inner {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        flex-direction: column; }

      @media only screen and (max-width: 690px) {
        flex-wrap: wrap;
        row-gap: 3*$base-width; }

      * {
        color: $light; }

      .location {
        display: flex;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          fill: $light;
          margin-right: .7rem;
          transition: $transition; } }

      .contact {
        display: flex;
        flex-wrap: wrap;
        row-gap: .5rem;

        h4, a, p {
          margin-right: 3 * $base-width;

          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            margin-right: 0;
            margin-bottom: $base-width;
            width: 100%; } }

        a {
          display: inline-flex;
          align-items: center;

          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            width: 100%; }

          &:hover {
            text-decoration: underline; }

          svg {
            width: 2rem;
            height: 2rem;
            fill: $light;
            margin-right: 1rem;
            transition: $transition; } } }

      #footer-menu {
        display: flex;
        list-style: none;

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          margin-top: 4 * $base-width; }

        li {

          &.active {
            a {
              text-decoration: underline; } }

          + li {
            margin-left: 2 * $base-width; } }

        a {
          &:hover {
            text-decoration: underline; } } } } } }
#sidebar {
  height: auto;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 8;
  right: 0;
  width: 5rem;
  top: 21rem;

  @media (max-width: #{map-get($breakpoints, s)}) {
    width: 4rem;
    // width: 100%
    // left: 0
    // top: unset
 }    // bottom: 0

  .sidebar-wrapper {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    z-index: 1;

    @media (max-width: #{map-get($breakpoints, s)}) {
      // flex-direction: row
      width: 100%; } }

  a {
    text-decoration: none; }
  p {
    color: $light; }

  .sidebar-item-link {
    display: flex;
    flex-direction: row;

    // @media (max-width: #{map-get($breakpoints, s)})
    //   flex: 0 0 100%

    &:hover {
      .sidebar-item {
        background-color: $light;

        svg {
          fill: $secondary;

          path {
            fill: $secondary;
            stroke: $secondary; } } }

      .sidebar-item-text {
        right: 5rem; } }

    .sidebar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 5rem;
      width: 5rem;
      justify-content: center;
      background-color: $secondary;
      transition: $transition;
      z-index: 999;
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.15);

      @media (max-width: #{map-get($breakpoints, s)}) {
        width: 100%;
        height: 4rem; }

      &__icon-wrapper {
        display: flex;
        align-items: center;

        svg {
          height: 3rem;
          width: 3rem;
          fill: $light;

          @media (max-width: #{map-get($breakpoints, s)}) {
            height: 2.5rem; }

          path {
            fill: $light;
            stroke: $light; } } } }

    .sidebar-item-text {
      min-width: 16rem;
      height: 5rem;
      position: fixed;
      right: -30rem;
      background-color: $secondary;
      color: $light;
      transition: $transition;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 900;
      padding: 0 $base-width;

      @media (max-width: #{map-get($breakpoints, s)}) {
        display: none; } } } }
