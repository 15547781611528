.btn {
    border-radius: 38px;
    width: fit-content;
    position: relative;
    padding: 1.5*$base-width 4*$base-width;
    transition: $transition;

    &-primary {
        background-color: $primary;
        color: $light;

        &:hover {
            color: $light;
            background-color: $primary-darker; } } }



