// Base
$base-width: .8rem;

// Fonts
$font-default: 'Inter', sans-serif;

// General
$dark: #222;
$light: #fff;

// Brand Colors
$primary: #1096ac;
$primary-darker: #0c7282;
$primary-lighter: #c0dde1;
$secondary: #f9f5e5;
$grey: #f2f2f2;


// Others
$divider: rgba($dark, .3);
$border-radius: 23px;
$bg-color: $light;
$box-shadow: 0 0 16px rgba(0,0,0,.08);

// Widths
$max: 1920px;
$boxed: 1520px;
$small: 1100px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1440px, "xxxl": 1680px);
