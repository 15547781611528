#errorPage {
    .btn {
        margin-top: 3*$base-width; }

    .block-404 {
        padding: 35 * $base-width 25 * $base-width;

        @media (max-width: #{map-get($breakpoints, xxxl)}) {
            padding: 30 * $base-width 16 * $base-width; }

        @media (max-width: #{map-get($breakpoints, l)}) {
            padding: 14 * $base-width 9 * $base-width; } } }


