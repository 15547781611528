a {
    color: $dark;
    text-decoration: none;
    transition: $transition;
    display: inline-block;

    &:hover {
        color: $primary; }

    &:focus {
        outline: 0; }

    &:not(.btn) {
        @media (max-width: #{map-get($breakpoints, s)}) {
            overflow-wrap: break-word; } } }

strong,
.bold {
    font-weight: 700; }

p + p {
    padding-top: 3 * $base-width; }

.text {
    ul,
    ol {
        padding-left: 3 * $base-width;

        li + li {}
        margin-top: $base-width;

        + p {
            padding-top: 3 * $base-width; } }

    * + ul,
    * + ol {
        margin-top: 2.5*$base-width; }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
        padding-top: 5 * $base-width; }

    blockquote {
        margin: 5 * $base-width 0 2 * $base-width;
        padding: 4 * $base-width;
        background: $bg-color;
        border-radius: $border-radius;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding: 3 * $base-width;
            border-radius: 20px; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 2 * $base-width; }

        p:last-child {
            padding-bottom: 0; } }

    a:not(.btn) {
        @media (max-width: #{map-get($breakpoints, s)}) {
            overflow-wrap: break-word; } } }

.align-center {
    text-align: center; }

button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $font-default;
    font-size: 5.2rem;
    line-height: 1.3;
    color: $primary;
    display: block;
    margin-bottom: 2 * $base-width;
    font-weight: 600;

    @media (max-width: 1496px) {
        font-size: 4.6rem; }

    @media only screen and (max-width: 1333px) {
        font-size: 3.5rem; }

    // @media only screen and (max-width: #{map-get($breakpoints, xl)})
    //     font-size: 3.5rem

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 3rem; } }

h2,
.h2 {
    font-family: $font-default;
    font-size: 4rem;
    line-height: 1.4;
    display: block;
    margin-bottom: 2 * $base-width;
    font-weight: 500;

    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 3rem; }

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2.5rem; } }

h3,
.h3 {
    font-family: $font-default;
    font-size: 2.2rem;
    line-height: 1.2;
    display: block;
    margin-bottom: 1.5 * $base-width;
    font-weight: 600;
    color: $primary;

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; } }
