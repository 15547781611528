.block-hero-image {
    padding: 0;

    .wrapper {
        position: relative;
        max-width: $max;
        display: flex;

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex-direction: column; }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background: url(../../assets/img/layout/hero-cover.svg);
            height: 88px;
            z-index: 900;
            background-position: center top;
            bottom: -20px;
            background-repeat: no-repeat !important;
            top: auto;

            @media only screen and (max-width: 1477px) {
                bottom: -29px; }

            @media only screen and (max-width: 1281px) {
                bottom: -38px; }

            @media only screen and (max-width: 1085px) {
                bottom: -46px; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                bottom: 169px;
                background: url(../../assets/img/layout/hero-cover-mobile.svg); }

            @media only screen and (max-width: 868px) {
                bottom: 161px; }

            @media only screen and (max-width: 768px) {
                bottom: 134px; }

            @media only screen and (max-width: 680px) {
                bottom: 127px; }

            @media only screen and (max-width: 647px) {
                bottom: 154px; }

            @media only screen and (max-width: 552px) {
                bottom: 147px; }

            @media only screen and (max-width: 434px) {
                bottom: 142px; }

            @media only screen and (max-width: 427px) {
                bottom: 167px; } }

        &::before {
            content: "";
            position: absolute;
            background: url(../../assets/img/layout/hero-cover-line.svg);
            bottom: -1px;
            top: auto;
            height: 107px;
            background-position: center top;
            background-repeat: no-repeat;
            transform: rotate(1deg);
            left: 0;
            width: 100%;
            z-index: 910;

            @media only screen and (max-width: 1281px) {
                bottom: -31px; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                left: 0;
                bottom: -63px; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; } }

        picture {
            padding-top: 49.2%;
            position: relative;
            display: block;
            width: 100%;
            flex: 0 0 100%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 66.2%; }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    object-position: right; } }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%; } }

        .block {
            position: absolute;
            top: 50px;

            @media only screen and (max-width: 1333px) {
                top: 10px; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                position: relative;
                top: 0;
                bottom: 0;
                padding-bottom: 0; } }

        .content {
            padding: 6.5rem;
            background-color: $light;
            max-width: 600px;
            border-radius: $border-radius;
            width: 100%;
            box-shadow: 0 0 20px rgba(0, 0, 0, .16);

            @media only screen and (max-width: 1727px) {
                padding: 6rem;
                max-width: 540px;
                width: 100%; }

            @media only screen and (max-width: 1333px) {
                padding: 5rem;
                max-width: 450px;
                width: 100%; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding: 4rem;
                max-width: 360px;
                width: 100%; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                position: relative;
                width: 100%;
                left: 0;
                right: 0;
                max-width: fit-content;
                transform: none;
                box-shadow: none;
                padding: 0; } } } }
