.block.block-gallery {
    margin: 0 auto;
    max-width: $max;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    p {
        max-width: 1112px; }

    .global-wrapper {
        padding-bottom: 4 * $base-width; }

    .gallery-grid {
        display: flex;
        flex-wrap: wrap;

        a.lightbox {
            padding: 1.5 * $base-width;
            position: relative;

            &:hover {
                .svg-wrapper {
                    opacity: 1; }
                picture {
                    &::after {
                        opacity: .6; } } }

            @media (max-width: #{map-get($breakpoints, l)}) {
                padding: $base-width; }

            &:first-child img {
                @media (max-width: #{map-get($breakpoints, s)}) {
                    border-radius: 0 !important; } }

            &:first-child img, &:first-child picture::after, &:nth-child(4) img, &:nth-child(4) picture::after, &:nth-child(7) img, &:nth-child(7) picture::after {
                border-radius: 0 20px 20px 0; }

            &:nth-child(2) img, &:nth-child(2) picture::after, &:nth-child(5) img, &:nth-child(5) picture::after, &:nth-child(8) img, &:nth-child(8) picture::after {
                border-radius: 20px; }

            &:nth-child(3) img, &:nth-child(3) picture::after, &:nth-child(6) img, &:nth-child(6) picture::after, &:nth-child(9) img, &:nth-child(9) picture::after {
                border-radius: 20px 0 0 20px; }

            &:first-child, &:nth-child(5), &:nth-child(7) {
                flex: 1 0 25%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; }

                @media (max-width: #{map-get($breakpoints, s)}) {
                    flex: 1 0 100%; } }

            &:nth-child(2), &:nth-child(6), &:nth-child(8) {
                flex: 1 0 40%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; } }

            &:nth-child(3), &:nth-child(4), &:nth-child(9) {
                flex: 1 0 35%;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    flex: 1 0 33.333%; } }

            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                @media (max-width: #{map-get($breakpoints, s)}) {
                    display: none; } }

            &:first-child, &:nth-child(4), &:nth-child(7) {
                padding-left: 0; }

            &:nth-child(3), &:nth-child(6), &:nth-child(9) {
                padding-right: 0; }

            &:first-child {
                @media (max-width: #{map-get($breakpoints, s)}) {
                    padding-right: 0; } }

            .svg-wrapper {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0; }

            svg {
                position: absolute;
                height: 100px;
                width: 100px;
                z-index: 1;
                fill: $light; } }

        picture {
            position: relative;
            display: block;
            padding-top: 370px;
            transition: all 0.3s ease-in-out;

            &::after {
                content: "";
                height: 100%;
                width: 100%;
                display: block;
                top: auto;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                background-color: $primary;
                opacity: 0;
                transition: all 0.3s ease-in-out; }

            @media (max-width: #{map-get($breakpoints, xxl)}) {
                padding-top: 270px; }

            @media (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 220px; }

            @media (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 280px; }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } }

        .more-btn {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            margin-top: 0;
            z-index: 1;
            background-color: $primary;
            color: $light;

            @media (max-width: #{map-get($breakpoints, s)}) {
                display: block; } } } }
