* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $light;
    scroll-behavior: smooth;

    // &.hidden
 }    //     overflow: hidden

.compensate-for-scrollbar {
    margin: 0 auto !important; }

body {
    -webkit-text-size-adjust: none;
    max-width: $max;
    margin: 0 auto;
    font-size: 1.8rem;
    color: $dark;
    line-height: 160%;
    font-family: $font-default;
    box-shadow: 0 0 15px 0 rgba(34, 34, 34, 0.13);
    font-weight: normal;
    overflow-x: hidden;

    // &.hidden
    //     overflow: hidden
 }    //     height: 100%

img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }


.block {
    padding: 14 * $base-width 25 * $base-width;

    @media (max-width: #{map-get($breakpoints, xxxl)}) {
        padding: 14 * $base-width 15 * $base-width; }

    @media (max-width: #{map-get($breakpoints, xxl)}) {
        padding: 10 * $base-width 9 * $base-width; }

    @media (max-width: #{map-get($breakpoints, m)}) {
        padding: 8 * $base-width 5 * $base-width; }

    @media (max-width: #{map-get($breakpoints, s)}) {
        padding: 8 * $base-width 3 * $base-width; }

    &.bg-dark {
        background-color: $grey;

        + .bg-dark {
            padding-top: 0; } } }


main {
    padding-top: 10rem;

    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        padding-top: 9rem; } }

.wrapper {
    max-width: $boxed;
    width: 100%;
    margin: 0 auto;

    &-max {
        max-width: $max;
        width: 100%;
        margin: 0 auto; }

    &-small {
        max-width: $small;
        width: 100%;
        margin: 0 auto; } }
